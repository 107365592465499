import React from 'react';

const OpenFileLink = () => {
  const OpenEulaFile = () => {
    // URL to your text file
    const textFileUrl = './filelinks/Software License.html';
    const ashok='./images/your-image.jpg';
    console.log(textFileUrl);
    console.log(ashok);

    // Open a new window with the text file
    window.open(textFileUrl, '_blank');
  };

  const OpenSoftwareFile = () => {
    // URL to your text file
    const textFileUrl = './filelinks/Inductive_Position_sensors_license.html';
    console.log(textFileUrl);

    // Open a new window with the text file
    window.open(textFileUrl, '_blank');
  };

  const OpenDisclaimerFile = () => {
    // URL to your text file
    const textFileUrl = './filelinks/IPS_Use_Disclaimer.html';
    console.log(textFileUrl);

    // Open a new window with the text file
    window.open(textFileUrl, '_blank');
  };

  return (
    <div class="container1">
    <div class="field">
      <a href="" onClick={OpenEulaFile}>EULA</a>
    </div>
    <div class="field">
      <a href="" onClick={OpenSoftwareFile}>Acknowledgements</a>
    </div>
    <div class="field">
      <a href="" onClick={OpenDisclaimerFile}>IPS Use Disclaimer</a>
    </div>
    </div>
  );
};

export default OpenFileLink;

import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import D3LineChart from './D3LineChart';
import D3LineChart1 from './D3LineChart1';
import D3LineChart2 from './D3LineChart2';
import D3LineChart3 from './D3LineChart3';
import SineWaveChart from './SineWaveChart';
import DacWaveChart from './DacWaveChart'; // Import DacWaveChart
import DacWaveChart2 from './VoltageChart'; // Import DacWaveChart2
import imageSrc from './images/your-image.jpg'; // Replace with the actual path to your image
import SineWaveComponent from './components/sinewave';
import CosWaveComponent from './components/coswave';
import OpenFileLink from './OpenFileLink';
import CookieConsent from './CookieConsent';

function App() {
  const centeredTextStyles = {
    textAlign: 'center',
  };

  const leftTextStyles = {
    textAlign: 'left',
    marginLeft: '100px',
  };

  const rightTextStyles = {
    textAlign: 'right',
    marginRight: '200px',
  };

  const largeText = {
    fontSize: '2ch',
    fontWeight: 550,
    marginRight: '3ch',
    marginTop: '2ch',
  };

  const widthBox = {
    width: '7ch',
  };

  const dutyStyle = {
    textAlign: 'center',
    fontSize: '2ch',
    fontWeight: 550,
    marginTop: '3ch',
    marginBottom: '3ch',
  };

  const [sliderValue, setSliderValue] = useState(180);
  const [enteredValue, setEnteredValue] = useState(sliderValue);
  const [dutyCycle, setDutyCycle] = useState(50);
  const [selectedOutput, setSelectedOutput] = useState('PWM output2');
  const [dragging, setDragging] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const svgRef = useRef();
  const circleRadiusFactor = 0.25;
  const lineLengthFactor = 0.8;
  const [name, setName] = useState('LX3302A');
  const [displayStatus, setDisplayStatus] = useState('inline');
  const [displaySine, setDisplaySine] = useState('inline')
  const [displayNegSine, setDisplayNegSine] = useState('none')
  const options = ['LX3302A', 'LX34311', 'LX34050', 'LX34070'];
  const outputsArray = ['PWM output[2KHz]', 'PWM output[1KHz]','PWM output[500Hz]','PWM output[250Hz]',
  'Sine/Cosine output ','Dac output' ];
  const outputValuesArray = ['PWM output2', 'PWM output1', 'PWM output3', 'PWM output4',
  'Sine output', 'Dac output'];
  const [outputs, setOutputs] = useState(outputsArray);
  const [outputValues, setOutputValues] = useState(outputValuesArray);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    const calculatedDutyCycle = parseFloat(((newValue / 360) * 100).toFixed(2));
    setSliderValue(newValue);
    setEnteredValue(newValue);
    setDutyCycle(calculatedDutyCycle);
  };

  const handleEnteredValueChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    const calculatedDutyCycle = parseFloat(((newValue / 360) * 100).toFixed(2));
    setEnteredValue(newValue);
    setSliderValue(newValue);
    setDutyCycle(calculatedDutyCycle);
  };

  const handleDropdownChange = (event) => {
    const selectedOutput = event.target.value;
    setSelectedOutput(selectedOutput);
  };

  const handleMouseDown = () => {
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      const svg = svgRef.current;
      const rect = svg.getBoundingClientRect();
      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const mouseX = event.clientX - rect.left - centerX;
      const mouseY = event.clientY - rect.top - centerY;

      const newSliderValue = (Math.atan2(mouseY, mouseX) * 180) / Math.PI;
      if (newSliderValue < 0) {
        setSliderValue(360 + newSliderValue);
      } else {
        setSliderValue(newSliderValue);
      }
    }
  };
  const handleGenerateButtonClick = () => {
    setIsGenerating(!isGenerating);
  };

  const handleDropdownChang = (event) => {
    const selectedName = event.target.value;
    if (selectedName === 'LX3302A') {
      setDisplayStatus('inline')
      setDisplaySine('inline')
      setOutputs(outputsArray)
      setOutputValues(outputValuesArray)
    }
    else if (selectedName === 'LX34311'){
      setDisplayStatus('inline')
      setDisplaySine('none')
      setOutputs([outputsArray[0],outputsArray[1],outputsArray[2],outputsArray[3],outputsArray[5]])
      setOutputValues([outputValuesArray[0],outputValuesArray[1],outputValuesArray[2],outputValuesArray[3],outputValuesArray[5]])
      if (selectedOutput === 'Sine output'){
        setSelectedOutput(outputValuesArray[0])
      }
    }
    else{
      setDisplayStatus('none')
      setDisplaySine('inline')
      setOutputs([outputsArray[4]])
      setOutputValues([outputValuesArray[4]])
      setSelectedOutput(outputValuesArray[4])
    }

    if(selectedName === 'LX34070'){
      setDisplayNegSine('inline')
    }
    else{
      setDisplayNegSine('none')
    }

    setName(selectedName);
  };
  
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging]);


  const circleRadius = 100 * circleRadiusFactor;
  const lineLength = circleRadius * lineLengthFactor;


  return (

  <div className="App">
  <h1 style={centeredTextStyles}>Inductive Position Sensors Basic Understanding</h1>
  <hr />
  <div className="container" style={{ marginTop: '30px', display: "flex", justifyContent: "center" }}>
      {/* Left Side */}
      <div style={leftTextStyles} className="left-content">
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '150px', display: 'flex', alignItems: 'center' }}>
          <label htmlFor="vol" style={largeText}>
            Move the target:
          </label>
          <input
            type="range"
            id="vol"
            name="vol"
            min="0"
            max="360"
            style={{marginTop: '23px' }}
            value={sliderValue}
            onChange={handleSliderChange}
          />
          <input
            type="number"
            value={enteredValue}
            style={{ ...widthBox, marginTop: '23px' }}
            onChange={handleEnteredValueChange}
          />
        </div> 
        <div>
        <label htmlFor="vol" style={largeText}>
          Select the product:
        </label>
      {/* Dropdown menu */}
      <select onChange={handleDropdownChang} value={name} style={largeText}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      </div>
      <div>
    </div>
        </div>
        <div style={{ position: 'relative' , display: 'inline-block'}}>
          <img src={imageSrc} alt="Your Image" className="chart-image"/>
          <div
              style={{
                position: "absolute",
                top: "17.5%",
                left: "6%",
                margin: "0",
                padding:"0",
                width: "30px",
                height: "25px",
                backgroundColor: "white",
                fontWeight: "750",
              }}
            > 1 x</div>
            <div
              style={{
                position: "absolute",
                top: "17.5%",
                left: "38%",
                margin: "0",
                padding:"0",
                width: "40px",
                height: "25px",
                backgroundColor: "white",
                fontWeight: "750",
              }}
            > 50 x</div>
            <div
              style={{
                position: "absolute",
                top: "52.5%",
                left: "26%",
                margin: "0",
                padding:"0",
                width: "30px",
                height: "25px",
                backgroundColor: "white",
                fontWeight: "750",
              }}
            > 1 x</div>
          <div
              style={{
                position: "absolute",
                top: "22.5%",
                left: "1%",
                margin: "0",
                padding:"0",
                width: "140px",
                height: "60px",
                backgroundColor: "white",
              }}
            >
              <CosWaveComponent data1={sliderValue}/>  
            </div>

            <div
              style={{
                position: "absolute",
                top: "43.5%",
                left: "20%",
                margin: "0",
                padding: "0",
                width: "155px",
                height: "60px",
                backgroundColor:"white",
              }}
            >
              <SineWaveComponent data1={sliderValue}/>
            </div>

            <div
              style={{
                position: "absolute",
                top: "23.25%",
                left: "34%",
                margin: "0",
                padding: "0",
                width: "125px",
                height: "60px",
                backgroundColor:"white",
              }}
            >
              <SineWaveComponent data1={90}/>
            </div>
          <div
          style={{
          position: 'absolute',
          top: '37%',
          left: '57.5%',
          transform: 'translate(-50%, -50%)',
          width: '137px',
          height: '196px',
          backgroundColor: 'darkblue',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          }}
          >
          <p style={{ color: 'white' }}>{name}</p>
          </div>
          <div
          style={{
            position: 'absolute',
            top: '37%',
            left: '69.35%',
            transform: 'translate(-50%, -50%)',
            width: '137px',
            height: '196px',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            }}>
            <div
            style={{
              width: '100%',
              backgroundColor: 'white'}}
            >
            <div
            style={{
              display: displayStatus}}>
            <span style={{ color: 'black', fontWeight: 600 }}>Digital Out (SENT)</span>
            <hr style={{ border: '1px solid black', margin: '2.5px 0' }}/>
            </div>
            <div
            style={{
              display: displayStatus}}>
            <span style={{ color: 'green', fontWeight: 600 }}>Analog Out <span style={{ color: 'darkblue' }}>/PWM</span></span>
            <hr style={{ border: '1px solid black', margin: '2.5px 0' }}/>
            </div>
            <div style={{
              display: displaySine}}>
            <span style={{ color: 'darkblue', fontWeight: 600, margin: '0 0 0 2em' }}>Cosine_p</span>
            <hr style={{ border: '1px solid black', margin: '2.5px 0' }}/>
            </div>
            <div style={{
              display: displayNegSine}}>
            <span style={{ color: 'darkblue', fontWeight: 600, margin: '0 0 0 2em' }}>Cosine_n</span>
            <hr style={{border: '1px solid black', margin: '2.5px 0' }}/>
            </div>
            <div style={{
              display: displaySine}}>
            <span style={{ color: 'red', fontWeight: 600, margin: '0 0 0 2em' }}>Sine_p</span>
            <hr style={{border: '1px solid black', margin: '2.5px 0' }}/>
            </div>
            <div style={{
              display: displayNegSine}}>
            <span style={{ color: 'red', fontWeight: 600, margin: '0 0 0 2em' }}>Sine_n</span>
            <hr style={{border: '1px solid black', margin: '2.5px 0' }}/>
            </div>
            </div>
          </div>
          <svg
            ref={svgRef}
            width="300"
            height="300"
            style={{ position: 'absolute', top: '28%', left: '40%', transform: 'translate(-110%, -50%)' }}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <circle cx="150" cy="150" r={circleRadius} fill="white" stroke="black" strokeWidth="4" />
            <line
              x1="150"
              y1={150 - circleRadius}
              x2="150"
              y2={150 + circleRadius}
              stroke="darkgreen"
              strokeWidth="12"
              transform={`rotate(${sliderValue} 150 150)`}
            />
            <circle cx="150" cy={152 - circleRadius} r="5" fill="red" transform={`rotate(${sliderValue} 150 150)`} />
          </svg>
        </div>
      </div>

      {/* Right Side */}
      <div style={{ ...rightTextStyles }} className="right-content">
        <div>
          <label htmlFor="outputSelect" style={largeText}>
            Select Output:
          </label>
          <select
            id="outputSelect"
            value={selectedOutput}
            onChange={handleDropdownChange}
            style={largeText}
          >
            {outputs.map((output, index) => (
          <option key={index} value={outputValues[index]}>
            {output}
          </option>
        ))}
          
          </select>
        </div>
        <div style={{ marginTop: '80px' }}>
        {selectedOutput === 'PWM output2' && <D3LineChart data1={sliderValue} />}
        {selectedOutput === 'PWM output1' && <D3LineChart1 data1={sliderValue} />}
        {selectedOutput === 'PWM output3' && <D3LineChart2 data1={sliderValue} />}
        {selectedOutput === 'PWM output4' && <D3LineChart3 data1={sliderValue} />}
          {selectedOutput === 'Sine output' && name==='LX34070' && <SineWaveChart data1={sliderValue} displayNeg={true} />}
          {selectedOutput === 'Sine output' && name!=='LX34070' && <SineWaveChart data1={sliderValue} displayNeg={false} />}
          {selectedOutput === 'Dac output' && isGenerating==false && (
            <DacWaveChart dutyCycle={sliderValue}  /> // Render DacWaveChart when generating is true
          )}
          {selectedOutput === 'Dac output' && isGenerating==true && (
            <DacWaveChart2 isGenerating={isGenerating}/> // Render DacWaveChart2 when generating is true
          )}
        </div>
      </div>
  </div>
  <div class="lines-container">
         <div class="line"></div>
         <p class="end-of-page-message">Copyright © 2024 Microchip Technology Inc.</p>
         <div class="line"></div>
         <div><OpenFileLink /></div>
         <div><CookieConsent /></div>

     </div>
</div>

  );
}


export default App;
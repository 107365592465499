import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const VoltageChart = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const width = 500;
    const height = 300;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const renderChart = (offset) => {
      svg.selectAll('*').remove();

      const xScale = d3.scaleLinear().domain([0, 360]).range([0, innerWidth]);
      const yScale = d3.scaleLinear().domain([0, 5]).range([innerHeight, 0]);

      const xAxis = d3
        .axisBottom(xScale)
        .ticks(10)
        .tickFormat((d) => ((d + offset) % 360).toFixed(1));
      const yAxis = d3.axisLeft(yScale).ticks(5);

      svg
        .append('g')
        .attr('transform', `translate(${margin.left},${innerHeight + margin.top})`)
        .call(xAxis);

      svg
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .call(yAxis);

      const data = d3.range(0, 361).map((target) => ((5 * target) / 360 + offset) % 5);

      const line = d3
        .line()
        .x((d, i) => xScale(i))
        .y((d) => yScale(d));

      svg
        .append('path')
        .datum(data)
        .attr('class', 'line')
        .attr('d', line)
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .attr('fill', 'none')
        .attr('stroke', 'black')
        .attr('stroke-width', 2);
    };

    let offset = 0;
    let intervalId = setInterval(() => {
      offset = (offset + 0.1) % 5; // Adjust the speed as needed
      renderChart(offset);
    }, 500); // Adjust the interval as needed

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="chart-container">
      <svg ref={svgRef} width={500} height={350}></svg>
    </div>
  );
};

export default VoltageChart;

import React, { useState, useEffect } from 'react';

function CookieConsent() {
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    useEffect(() => {
        // Check if cookies have been accepted
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        const isCookiesAccepted = cookies.includes('cookies_accepted=true');
        if (isCookiesAccepted) {
            setCookiesAccepted(true);
        }
    }, []);

    const acceptCookies = () => {
        // Set cookies
        document.cookie = 'cookies_accepted=true;';
        setCookiesAccepted(true);
    };

    return (
        !cookiesAccepted && (
            <div id="cookie-consent-row" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#f0f0f0', padding: '10px' }}>
                <div id="cookie-consent-content" style={{ overflow: 'hidden' }}>
                    <div id="cookie-consent-text" style={{ float: 'left', marginLeft: '60px', marginBottom: '10px' }}>
                        <span style={{ fontSize: '16px' }}>
                            This website uses cookies for analytics, personalization, and other purposes. Click to learn more. By continuing to browse, you agree to our use of cookies as described in our Cookies Statement.
                        </span>
                    </div>
                    <div id="cookie-consent-buttons" style={{ float: 'right', marginRight: '200px' }}>
                        <button onClick={() => window.open('https://www.microchip.com/en-us/about/legal-information/privacy-notice', '_blank')} style={{ backgroundColor: 'transparent', color: '#000', border: 'none', padding: '5px 10px', cursor: 'pointer', textDecoration: 'none' }}>Privacy Notice</button>
                        <button onClick={acceptCookies} style={{ backgroundColor: '#00008B', color: 'white', border: 'none', padding: '5px 10px', cursor: 'pointer' }}>Accept Cookies</button>
                    </div>
                </div>
            </div>
        )
    );
}

export default CookieConsent;

import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import imageSrc from './images/your-image.jpg'; // Replace with the actual path to your image

const DacWaveChart = ({ dutyCycle }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = 500;
    const height = 300;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
  
    // Calculate the voltage based on the duty cycle
    const calculateDacValue = (dutyCycle) => {
      return (dutyCycle / 360) * 5; // Adjust as needed
    };
  
    const dacValue = calculateDacValue(dutyCycle);
  
    svg.selectAll('*').remove();
  
    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
  
    // Define the scales
    const xScale = d3.scaleLinear().domain([0, 360]).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain([0, 5]).range([innerHeight, 0]);
  
    // Draw axes
    g.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale));
  
    g.append('g')
      .call(d3.axisLeft(yScale));
  
    // Draw the diagonal line (from (0,0) to (360,5))
    g.append('line')
      .attr('x1', xScale(0))
      .attr('y1', yScale(0))
      .attr('x2', xScale(360))
      .attr('y2', yScale(5))
      .attr('stroke', 'blue')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5,5'); 
  
    // Calculate the intersection point
    const intersectionX = xScale(dutyCycle);
    const intersectionY = yScale(dacValue);
  
    // Draw a circle at the intersection point
    g.append('circle')
      .attr('cx', intersectionX)
      .attr('cy', intersectionY)
      .attr('r', 10)
      .attr('fill', 'red');
  
  }, [dutyCycle]);

  return (
    <div className="chart-container">
      <svg ref={svgRef} width={500} height={350}></svg>
    </div>
  );
};

export default DacWaveChart;




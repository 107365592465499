import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import imageSrc from './images/your-image.jpg'; // Replace with the actual path to your image

function D3LineChart3({ data1 }) {
  const centeredTextStyles = {
    textAlign: 'center',
  };
  const svgRef = useRef();
  const [animationFrame, setAnimationFrame] = useState(null);
  const [selectedOutput, setSelectedOutput] = useState('PWM output'); // Initialize with a default value

  useEffect(() => {
    const svg = d3.select(svgRef.current);
  
    const width = 500;
    const height = 300;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = d3.scaleLinear().domain([0, 360]).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain([0, 6]).range([innerHeight, 0]);
  
    const line = d3.line().x((_, i) => xScale(i)).y(d => yScale(d));
  
    const renderChart = () => {
      const cycles = 3; // Number of cycles to display
      const cycleWidth = Math.floor(360 / cycles);
  
      // Create waveform data
      const data = [];
      for (let i = 0; i < 360 * cycles; i++) {
        const pwmValue = i % cycleWidth < cycleWidth * (data1 / 360) ? 5 : 0;
        data.push(pwmValue);
      }
  
      svg.selectAll('*').remove();
  
      const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
  
      const tickInterval = 1000; // Define the interval you want (e.g., 500 units)
      const tickValues = Array.from({ length: Math.ceil(6000 / tickInterval) + 1 }, (_, i) => i * tickInterval);

      g.append('g')
        .attr('transform', `translate(0, ${innerHeight})`)
        .call(
          d3.axisBottom()
            .scale(d3.scaleLinear().domain([0, 6000]).range([0, innerWidth]))
            .tickValues(tickValues)
        );

      g.append('g').call(d3.axisLeft(yScale));

      // Add X-axis label
      g.append('text')
        .attr('class', 'x-label')
        .attr('x', innerWidth / 2)
        .attr('y', innerHeight + margin.top + 20) // Adjust the position as needed
        .text('Time(Us)');

      // Add Y-axis label
      g.append('text')
        .attr('class', 'y-label')
        .attr('x', -innerHeight / 2)
        .attr('y', -margin.left + 10) // Adjust the position as needed
        .attr('transform', 'rotate(-90)')
        .text('Voltage(Volts)');
  
      g
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', 2)
        .attr('d', line(data));
  
      // Request the next animation frame
      requestAnimationFrame(renderChart);
    };
  
    // Render the chart initially
    renderChart();
  
    return () => {
      // Cancel the animation frame when the component is unmounted
      cancelAnimationFrame(animationFrame);
    };
  }, [data1]);


  const handleDropdownChange = (event) => {
    setSelectedOutput(event.target.value);
  };

  const renderSineChart = () => {
    const svg = d3.select(svgRef.current);

    const width = 2000;
    const height = 300;
    const margin = { top: 20, right: 20, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = d3.scaleLinear().domain([0, 360]).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain([-1, 1]).range([innerHeight, 0]);

    const sineLine = d3.line().x((_, i) => xScale(i)).y((_, i) => yScale(Math.sin((i / 180) * Math.PI)));

    const renderSineChartInternal = () => {
      const data = Array.from({ length: 360 * 3 }, (_, i) => Math.sin((i / 180) * Math.PI));
      svg.selectAll('*').remove();

      const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
      const tickValues = Array.from({ length: 361 }, (_, i) => i * 500); // Generate tick values with 500 microseconds interval

      g.append('g')
        .attr('transform', `translate(0, ${innerHeight})`)
        .call(
          d3.axisBottom(xScale)
            .tickValues(tickValues) // Set tick values
            .tickFormat(d => `${d / 1000} ms`) // Format ticks to display milliseconds
        );

    g.append('g').call(d3.axisLeft(yScale));

      // Add X-axis label
      g.append('text')
        .attr('class', 'x-label')
        .attr('x', innerWidth / 2)
        .attr('y', innerHeight + margin.top + 20) // Adjust the position as needed
        .text('Time');

      // Add Y-axis label
      g.append('text')
        .attr('class', 'y-label')
        .attr('x', -innerHeight / 2)
        .attr('y', -margin.left + 10) // Adjust the position as needed
        .attr('transform', 'rotate(-90)')
        .text('Amplitude');

      g
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', 'green')
        .attr('stroke-width', 2)
        .attr('d', sineLine(data));

      // Request the next animation frame
      requestAnimationFrame(renderSineChartInternal);
    };

    // Render the sine chart initially
    renderSineChartInternal();

    return () => {
      // Cancel the animation frame when the component is unmounted
      cancelAnimationFrame(animationFrame);
    };
  };
  return (
    <div className="chart-container">
      <svg ref={svgRef} width={500} height={350}></svg>
    </div>
  );

}

export default D3LineChart3;


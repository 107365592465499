import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import * as d3 from "d3";

function CosWaveComponent({ data1 }) {
  const svgRef = useRef();

  // Define width and height here
  const width = 500;
  const height = 250;
  const margin = { top: 20, right: 20, bottom: 40, left: 40 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Adjust the xScale domain to cover 4 cycles (0 to 1440 degrees)
    const xScale = d3.scaleLinear().domain([0, 9000]).range([0, innerWidth]);

    // Adjust the domain range for the new functions
    const yScale = d3.scaleLinear().domain([-1, 1]).range([innerHeight, 0]);

    // Define the new functions
    const sinePositiveLine = d3
      .line()
      .x((d) => xScale(d.data1))
      .y((d) => yScale(Math.sin((d.data1 * Math.PI) / 180) * Math.abs(Math.cos((data1 * Math.PI) / 180))))
      .curve(d3.curveBasis);

    // Generate data for 4 cycles
    const data = d3.range(0, 9000, 1).map((d) => ({ data1: d }));

    svg.selectAll("*").remove();

    // Create a group for the chart content, including axis
    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Draw Positive Sine curve
    g.append("path")
      .datum(data)
      .attr("class", "sine-curve")
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 2)
      .attr("d", sinePositiveLine(data));
  }, [data1, width, height]);

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="100%"
      viewBox="-5 20 470 225"
      preserveAspectRatio="xMidYMid meet"
    ></svg>
  );
}

export default CosWaveComponent;

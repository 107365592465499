import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import * as d3 from 'd3';
import imageSrc from './images/your-image.jpg'; // Replace with the actual path to your image

function SineWaveChart({ data1, displayNeg }) {
  const svgRef = useRef();

  // Define width and height here
  const width = 500;
  const height = 300;
  const margin = { top: 20, right: 20, bottom: 40, left: 40 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const xScale = d3.scaleLinear().domain([0, 360]).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain([0, 5]).range([innerHeight, 0]); // Adjust the domain range for the new functions

    const xAxis = d3.axisBottom(xScale).tickSize(0);
    const yAxis = d3.axisLeft(yScale).tickSize(0);

    // Define the new functions
    const sinePositiveLine = d3
      .line()
      .x((d) => xScale(d.data1))
      .y((d) => yScale(2 * Math.sin((d.data1 * Math.PI) / 180) + 2.5)) // New function: 2sin(x) + 2.5
      .curve(d3.curveBasis);

    const sineNegativeLine = d3
      .line()
      .x((d) => xScale(d.data1))
      .y((d) => yScale(-1*2 * Math.sin((d.data1 * Math.PI) / 180) + 2.5)) // New function: 2sin(x) + 2.5
      .curve(d3.curveBasis);

    const cosinePositiveLine = d3
      .line()
      .x((d) => xScale(d.data1))
      .y((d) => yScale(2 * Math.cos((d.data1 * Math.PI) / 180) + 2.5)) // New function: 2cos(x) + 2.5
      .curve(d3.curveBasis);

    const cosineNegativeLine = d3
      .line()
      .x((d) => xScale(d.data1))
      .y((d) => yScale(-1*2 * Math.cos((d.data1 * Math.PI) / 180) + 2.5)) // New function: 2cos(x) + 2.5
      .curve(d3.curveBasis);

    const data = d3.range(0, 360, 1).map((d) => ({ data1: d }));

    svg.selectAll('*').remove();

    // Create a group for the chart content, including axis
    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add X-axis
    g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(xAxis);

    // Add Y-axis
    g.append('g')
      .attr('class', 'y-axis')
      .call(yAxis);

    // Draw the fault regions
      // Create horizontal grid lines for the region 4.5 to 5
for (let y = 4.5; y <= 5; y += 0.05) {
  g
    .append('line')
    .attr('class', 'grid-line')
    .attr('x1', 0)
    .attr('y1', yScale(y))
    .attr('x2', innerWidth)
    .attr('y2', yScale(y))
    .attr('stroke', 'rgba(0, 0, 0, 0.2)'); // Red grid lines for 4.5 to 5

    
}

// Create horizontal grid lines for the region -1 to 0.5
for (let y = 0; y <= 0.5; y += 0.05) {
  g
    .append('line')
    .attr('class', 'grid-line')
    .attr('x1', 0)
    .attr('y1', yScale(y))
    .attr('x2', innerWidth)
    .attr('y2', yScale(y))
    .attr('stroke', 'rgba(0, 0, 0, 0.2)'); // Red grid lines for -1 to 0.5
}

    g
    .append('text')
    .attr('x', 150) // Adjust the X position as needed
    .attr('y', yScale(4.7)) // Adjust the Y position as needed
    .attr('class', 'test-region-text')
    .text('Fault Region');

    g
    .append('text')
    .attr('x', 150) // Adjust the X position as needed
    .attr('y', yScale(0.12)) // Adjust the Y position as needed
    .attr('class', 'test-region-text')
    .text('Fault Region');

    // Draw Positive Sine curve
    g
      .append('path')
      .datum(data)
      .attr('class', 'sine-curve')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 2)
      .attr('d', sinePositiveLine(data));


    if (displayNeg){
    // Draw Negative Sine curve
    g
      .append('path')
      .datum(data)
      .attr('class', 'sine-curve')
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 2)
      .attr('d', sineNegativeLine(data));
    }
    // Draw Positve Cosine curve
    g
      .append('path')
      .datum(data)
      .attr('class', 'cosine-curve')
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .attr('stroke-width', 2)
      .attr('d', cosinePositiveLine(data));

    if(displayNeg){
    // Draw Negative Cosine curve
    g
      .append('path')
      .datum(data)
      .attr('class', 'cosine-curve')
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .attr('stroke-width', 2)
      .attr('d', cosineNegativeLine(data));
    }
    // Calculate the X and Y positions of the dot based on the data1
    const dotX = xScale(data1);
    const dotY = yScale(2 * Math.sin((data1 * Math.PI) / 180) + 2.5);

    const dotXNeg = xScale(data1);
    const dotYNeg = yScale(-2 * Math.sin((data1 * Math.PI) / 180) + 2.5);

    // Draw a dot for Sine
    g
      .append('circle')
      .attr('class', 'dot-sine')
      .attr('cx', dotX)
      .attr('cy', dotY)
      .attr('r', 8)
      .attr('fill', 'red');

    if (displayNeg){
    // Draw a dot for Neg Sine
    g
      .append('circle')
      .attr('class', 'dot-sine')
      .attr('cx', dotXNeg)
      .attr('cy', dotYNeg)
      .attr('r', 8)
      .attr('fill', 'red');
    }
    // Calculate the X and Y positions of the dot based on the data1 for Cosine
    const dotXCosine = xScale(data1);
    const dotYCosine = yScale(2 * Math.cos((data1 * Math.PI) / 180) + 2.5);

    const dotXNegCosine = xScale(data1);
    const dotYNegCosine = yScale(-2 * Math.cos((data1 * Math.PI) / 180) + 2.5);

    // Draw a dot for Cosine
    g
      .append('circle')
      .attr('class', 'dot-cosine')
      .attr('cx', dotXCosine)
      .attr('cy', dotYCosine)
      .attr('r', 8)
      .attr('fill', 'orange');

    if(displayNeg){
    // Draw a dot for Neg Cosine
    g
      .append('circle')
      .attr('class', 'dot-cosine')
      .attr('cx', dotXNegCosine)
      .attr('cy', dotYNegCosine)
      .attr('r', 8)
      .attr('fill', 'orange');
    }
    // Update X-axis label to "Degrees"
    g
      .append('text')
      .attr('x', innerWidth / 2)
      .attr('y', innerHeight + margin.top + 5)
      .text('Degrees');

    // Update Y-axis label to "Amplitude"
    g
      .append('text')
      .attr('x', -innerHeight / 2)
      .attr('y', -margin.left + 10)
      .attr('transform', 'rotate(-90)')
      .text('Amplitude');
  }, [data1, width, height]);

  return (
    <div className="App">
      <svg ref={svgRef} width={width} height={height}></svg>
    </div>
  );
}

export default SineWaveChart;
